// IOSEditScreenListItem.js
import React from 'react';
import {useState, useEffect} from 'react';
import {  insertAtPosition, 
          removeAtPosition, 
          updateScreenName,
          updateImageFromInputFeild,
          updateDescription,
          updateImageURLFromTextFeild,
          updatePosition,
          updateEventTypes
        } from '../../store/slices/iOSUserFlowSlice'
import { uploadIOSImages, updateIOSScreen,} from '../../store'
import { useDispatch } from 'react-redux'
const formatFileSize = require('../../utils/formatFileSize');
const KEYS = require('../../config/keys')
import { formatFlowName } from '../../utils/FlowNameFormatter';
import UIField from './UserInteractionsSearchAndSelect/UIField'

const IOSEditScreenListItem = ({index, screen, flow, pendingScreenUpdates, app}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(screen.name);
  const [position, setPosition] = useState(screen.position);
  const [description, setDescription] = useState(screen.description);
  // const [image, setImage] = useState(screen.image.image_url);
   const [imageUrl, setImageUrl] = useState("");
   const [isTry, setIsTry] = useState(false);


   // Effect to update state when screen prop changes
  useEffect(() => {
  setName(screen.name);
   setDescription(screen.description);
   setPosition(screen.position)
  //   setImage(screen.image.image_url)
  }, [screen]); // Depend on screen prop to trigger updates


  // State to keep track of original data
  const [originalData, setOriginalData] = useState({
                    name: screen.name,
                    description: screen.description,
                    image: screen.image.image_url,
                    position:screen.position,
                    image_url: ""
                  });


  var existingUpdate;
  //checking if the screen is existing screen
  if(screen.id){
    const id = screen.id
    
    const updateIndex = pendingScreenUpdates.findIndex(update => update.id === id);
    existingUpdate = updateIndex !== -1 ? pendingScreenUpdates[updateIndex] : null;
          if (existingUpdate) {
                      // Update existing entry
                      //existingUpdate.name = newName;
                    // console.log("this screen has updates", id)
                  } 
  }

//console.log(screen)

  const toggleTry = () => {
    setIsTry(!isTry); // Toggle the isTry state
  };

  const handleClickAfterButton = (index) => {
    const numericValue = parseInt(index+1); // Convert to number
   // console.log("handleClickAfterButton", numericValue)
   const formattedName = formatFlowName(flow.name, app.name, app.__typename);
   const prefixImagefileNametoUseWhenUploading = formattedName
   //dispatch(insertAtPosition(numericValue))
   dispatch(insertAtPosition({ index: numericValue, prefixImagefileNametoUseWhenUploading }));
  };

  const handleClickBeforeButton = (index) => {
    const numericValue = parseInt(index); // Convert to number
   // console.log("handleClickBeforeButton", numericValue)
   const formattedName = formatFlowName(flow.name, app.name, app.__typename);
   const prefixImagefileNametoUseWhenUploading = formattedName
   dispatch(insertAtPosition({ index: numericValue, prefixImagefileNametoUseWhenUploading }));
  };

   const handleClickRemoveButton = (index) => {
    const numericValue = parseInt(index); // Convert to number
    //console.log("handleClickRemoveButton", numericValue)
    dispatch(removeAtPosition(numericValue))
  };

  const handleImageFromInputFeildChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
    const imageUrl = URL.createObjectURL(file);
    const fileSize = formatFileSize(file.size);
    const formattedName = formatFlowName(flow.name, app.name, app.__typename);
    const filename = file.name
    
    dispatch(updateImageFromInputFeild({ index, filename, imageUrl, fileSize, formattedName  }));
    
    } else {
      //setImage(null);
    }
  };

const handleImageURLFromTextFeildBlur = (e) => {
    const newURL = e.target.value;
    if (newURL.includes("https://pub-7dee58dc74284d3faf70889f5a2cde0e.r2.dev/") && newURL.length > 0) { // Check if newURL contains the specified address and length is greater than 0
        var hasImageURLChanged = newURL !== originalData.image_url;
        if (hasImageURLChanged) { // Dispatch only if newURL contains the specified address, length is greater than 0, and has changed
            dispatch(updateImageURLFromTextFeild({ index, newURL }));
        }
    }
}


  const handleNameBlur = (e) => {
    //console.log(e.target.value)
    const newName = e.target.value;
    var hasNameChanged = newName !== originalData.name
    //console.log("hasNameChanged", hasNameChanged)
     dispatch(updateScreenName({ id: index, newName, hasNameChanged}));
  }


const handleDescriptionChange = (e) => {
    //console.log(e.target.value)
    const newDescription = e.target.value;
    var hasDescriptionChanged = newDescription !== originalData.description
    //console.log("hasDescriptionChanged", hasDescriptionChanged)
    dispatch(updateDescription({ id: index, newDescription, hasDescriptionChanged}));
  }

const handleUpdateScreenClick = (screenUpdates) => {
  //console.log("handleUpdateScreenClick", screenUpdates)
  const { id, ...updates } = screenUpdates
  //console.log(id)
  

  // Check if the event_types array exists and process it
    // if (event_types && Array.isArray(event_types)) {
    //     // Extracting id from each object in the event_types array
    //     const eventTypeIds = event_types.map(event => event.id);
    //     updates.event_types = eventTypeIds;  // Adding the new array to updates under the key 'eventTypeIds'
    // }

  //console.log (updates)
  dispatch(updateIOSScreen({screenId: id, screen: updates}))
  }


const handlePositionBlur = (e) => {
      const inputValue = e.target.value; // This is a string
      const newPosition = parseInt(inputValue); // Convert to number
      var hasPositionChanged = newPosition !== originalData.position
      setPosition(newPosition)
      dispatch(updatePosition({ id: index, newPosition, hasPositionChanged}));
      
  }


 // console.log(didWeReplacePlaceholderImage())
  return (
  <div className="ui form">
              <img className="flow-details-page-webapp-images" src={screen.image.image_url}/> 
              <div>
                <button className="ui mini right floated button" onClick={()=> handleClickAfterButton(index)}>+ After</button>
               {index === 0 ? <button className="ui mini left floated button" onClick={()=> handleClickBeforeButton(index)}>+ Before</button> : null}
               {screen.isNew ? <button className="ui mini left floated button" onClick={()=> handleClickRemoveButton(index)}>Remove</button> : null}
                <div className="field">
               {  screen.isNew  && (
                    <div className="ui positive message">
                      <i className="close icon"></i>
                      <div className="header">
                        New Screen
                      </div>
                       <ul className="list">
                    
                      </ul>
                    </div>
                )} 
                {  existingUpdate  && (
                    <div className="ui negative message">
                      <i className="close icon"></i>
                      <div className="header">
                        Unsaved Changes
                      </div>
                    </div>
                )} 
                </div>
              
              </div>         
                            <div className="field">
                             <input type="file" onChange={handleImageFromInputFeildChange} />
                             </div>     
                            <div className="field">
                            <h3>Original File Name</h3>
                            <p>{screen.filename}</p>
                            </div>
                            <div className="field">
                            <h3>Lid#</h3>
                            <p>{screen.lid} </p>
                            </div>
                             <div className="field">
                            <h3>gid# {screen.id} </h3>
                            </div>
                            <div className={existingUpdate && existingUpdate.name ? "field error" : "field"}>
                            <h3>screen name</h3>
                            <input type="text" value={name} onBlur={handleNameBlur} onChange={(e) => setName(e.target.value)} />
                            </div>
                            
                            <div className={existingUpdate && existingUpdate.position ? "field error" : "field"}>
                            <h3>position</h3>
                            <input type="text" placeholder="numbers only" value={position} onBlur={handlePositionBlur} onChange={(e) => setPosition(e.target.value)}/>
                            </div>


                            <div className="field">
                            <h3>imageUrl</h3>
                            <input type="text" value={imageUrl} onBlur={handleImageURLFromTextFeildBlur} onChange={(e) => setImageUrl(e.target.value)}  />
                            </div>
                            
                            <div className={existingUpdate && existingUpdate.event_types ? "field error" : "field"}>
                            <UIField label="User Interactions (events_types)" appType={app.__typename} value={screen.event_types} index={index}/>
                            </div>



                             <div className={existingUpdate && existingUpdate.description ? "field error" : "field"}>
                             <h3>description</h3>
                            {   !isTry ? (<textarea
                                          rows="3" 
                                          value={description}
                                          onBlur={handleDescriptionChange}
                                          onChange={(e) => setDescription(e.target.value)}
                                    />)
                                    :
                                    (<p dangerouslySetInnerHTML={{ __html: screen.description }} />) 
                            }
                              </div>
                              <div className="ui toggle checkbox">
                              <input type="checkbox"
                                checked={isTry}
                                 onChange={toggleTry} />
                                <label>Test in HTML</label>
                              </div>
                            <div className="field">
                            {/* {existingUpdate ? <button className="ui right floated teal button" onClick={()=> handleUpdateScreenClick(existingUpdate)}>Update Screen</button> : null} */}
                            </div>  

    </div>  
  );
};


export default IOSEditScreenListItem

//<input type="number" value={screen.position} placeholder="numbers only" onChange={(e) => setPosition(e.target.value)}/>

