import { createAsyncThunk } from '@reduxjs/toolkit';
const KEYS = require('../../config/keys')

const fetchUserFlowByAppId = createAsyncThunk('fetchUserFlowByAppId/fetch', async(term) =>{
//console.log("fetchUserFlowByAppId thunk ", term)
 // console.log(KEYS.WEBAPPS_PUBLISH_STATUS)
	return fetch(KEYS.GRAPHQL_URL, {
	//const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
          appId: term.idfromURL,
          limit: term.limit,
          skip: term.skip,
          publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
    	query: `
              query ($appId: ID!, $limit: Int, $skip: Int, $publishStatus: String!) {
              userFlowByAppId (appId: $appId, limit: $limit, skip: $skip, publishStatus: $publishStatus) {
                        count 
                        hasMore
                        userflow {
                          id
                          name
                          description
                          publishStatus
                          position
                          userInteractionsCount
                          totalUserInteractions
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              position
                              event_types {
                                id
                                name
                              }
                              image {
                                  id
                                  image_url
                                
                                }
                            }
                          }
                        } 
                    }
            }
            `	
    })
  }).then(res => res.json())
   // console.log(response)
})


const fetchiOSUserFlowByAppId = createAsyncThunk('fetchiOSUserFlowByAppId/fetch', async(term) =>{
//console.log("fetchiOSUserFlowByAppId thunk ", term)
 // console.log(KEYS.WEBAPPS_PUBLISH_STATUS)
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
          appId: term.idfromURL,
          limit: term.limit,
          skip: term.skip,
          publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
      query: `
              query ($appId: ID!, $limit: Int, $skip: Int, $publishStatus: String!) {
              userFlowByAppId (appId: $appId, limit: $limit, skip: $skip, publishStatus: $publishStatus) {
                        count 
                        hasMore
                        userflow {
                          id
                          name
                          description
                          publishStatus
                          position
                          userInteractionsCount
                          totalUserInteractions
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              position
                              event_types {
                                id
                                name
                              }
                              image {
                                  id
                                  image_url
                                 
                                }
                            }
                          }
                        } 
                    }
            }
            ` 
    })
  }).then(res => res.json())
   // console.log(response)
})


const fetchAllUserFlowNamesByAppId = createAsyncThunk('fetchAllUserFlowNamesByAppId/fetch', async(term) =>{
//console.log("fetchAllUserFlowNamesByAppId thunk ", term)
 // console.log(KEYS.WEBAPPS_PUBLISH_STATUS)
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      variables: {
          appId: term,
          publishStatus: KEYS.WEBAPPS_PUBLISH_STATUS
      },
      query: `
              query ($appId: ID!, $limit: Int, $skip: Int, $publishStatus: String!) {
              userFlowByAppId (appId: $appId, limit: $limit, skip: $skip, publishStatus: $publishStatus) {
                        userflow {
                          id
                          name
                          position
                          description
                          publishStatus
                          userInteractionsCount
                          totalUserInteractions
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              position
                              event_types {
                                id
                                name
                              }
                              image {
                                  id
                                  image_url
                                 
                                }
                            }
                            
                          }
                        } 
                    }
            }
            ` 
    })
  }).then(res => res.json())
   //console.log(response)
})



const fetchUserFlowById = createAsyncThunk('fetchUserFlowById/fetch', async(term) =>{
  //console.log("fetchUserFlowById thunk ", term)
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
      query: `
             {
              userFlowId (flowId: "${term}") { 
                          id
                          name
                          description
                          publishStatus
                          position
                          userInteractionsCount
                          totalUserInteractions
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              position
                              event_types {
                                id
                                name
                              }
                              image {
                                  id
                                  image_url
                                 
                                }
                            }
                          }
                         app {
                           ... on Web {
                            __typename
                            name
                            id
                            description
                            logo_url
                            initial_image_url
                            categories {
                                id
                                name
                            }
                           }
                         } 
                        } 
                    
            }
            ` 
    })
  }).then(res => res.json())
   // console.log(response)
})


const fetchIOSUserFlowById = createAsyncThunk('fetchIOSUserFlowById/fetch', async(term, { dispatch, getState }) =>{
  //console.log("fetchIOSUserFlowById thunk ", term)
   //const state = getState().iosuserflows.selectedFlow
   //console.log(state)
  return fetch(KEYS.GRAPHQL_URL, {
  //const response = fetch(KEYS.GRAPHQL_URL, {
    method: 'POST',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      //query: query,
      //variables: variables
      query: `
             {
              userFlowId (flowId: "${term}") { 
                          id
                          name
                          description
                          publishStatus
                          position
                          userInteractionsCount
                          totalUserInteractions
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              position
                              event_types {
                                id
                                name
                              }
                              image {
                                  id
                                  image_url
                                 
                                }
                            }
                          }
                         app {
                           ... on iOS {
                            __typename
                            name
                            id
                            description
                            logo_url
                            initial_image_url
                            categories {
                                id
                                name
                            }
                           }
                         } 
              } 
                    
            }
            ` 
    })
  }).then(res => res.json())
   // console.log(response)
})

const createUserflowWithoutScreens = createAsyncThunk('createUserflowWithoutScreens/update', async({ userflow }) => {
  //console.log("createUserflowWithoutScreens")
  //console.log(userflow)
  

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          userflow
        },
          query: `
          mutation CreateUserflowWithoutScreens($userflow: createUserflowInput) {
            createUserflowWithoutScreens(userflow: $userflow) {
              name
              id
              position
              description
              publishStatus
              userInteractionsCount
              totalUserInteractions
          }
        }
        `
      })
  }).then(res => res.json())
})


const updateUserflowByID = createAsyncThunk('updateUserflowByID/update', async({ flowId, userflow }) => {
  //console.log("updateUserflowByID")
  //console.log(userflow)
  //console.log(flowId)
  

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          userflow,
          flowId
        },
          query: `
          mutation UpdateUserflowByID($flowId: ID!, $userflow: updateUserflowInput) {
            updateUserflowByID(flowId: $flowId, userflow: $userflow) {
                          id
                          name
                          description
                          publishStatus
                          position
                          userInteractionsCount
                          totalUserInteractions
                          screens {
                            count
                            screen {
                              id
                              name
                              description
                              position
                              event_types {
                                id
                                name
                              }
                              image {
                                  id
                                  image_url
                                  
                                }
                            }
                          }
                         app {
                           ... on iOS {
                            __typename
                            name
                            id
                            description
                            logo_url
                            initial_image_url
                            categories {
                                id
                                name
                            }
                           }

                           ... on Web {
                               __typename
                            name
                            id
                            description
                            logo_url
                            initial_image_url
                            categories {
                                id
                                name
                            }
                           }
                         }
           
          }
        }
        `
      })
  }).then(res => res.json())
})


const addScreensObjsToUserflow = createAsyncThunk('addScreensObjsToUserflow/update', async({ flowId, screens }) => {
  //console.log("addScreensObjsToUserflow")
  // console.log(flowId)
  //console.log(screens)
  

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          screens,
          flowId
        },
          query: `
          mutation addScreensObjsToUserflow ($flowId: ID!, $screens: [addScreensToUserflowInput]!) {
            addScreensObjsToUserflow(flowId: $flowId, screens: $screens) {
              id
              screens {
                  count
                  screen {
                    id
                    name
                    description
                    position
                    event_types {
                        id
                        name
                    }
                    image {
                        id
                        image_url
                       
                      }
                  }
                } 
          }
        }
        `
      })
  }).then(res => res.json())
})


const updateUserFlowScreens = createAsyncThunk('updateUserFlowScreens/update', async({ flowId, screens }) => {
  console.log("updateUserFlowScreens")
   //console.log(flowId)
   //console.log(screens)
  

  return fetch(KEYS.GRAPHQL_URL, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
          variables: {
          screens,
          flowId
        },
          query: `
          mutation updateUserFlowScreens($flowId: ID!, $screens: [updateMultipleScreensInput]!) {
              updateUserFlowScreens(flowId: $flowId, screens: $screens) {
              id
              userInteractionsCount
              totalUserInteractions
              screens {
                  count
                  screen {
                    id
                    name
                    description
                    position
                    event_types {
                        id
                        name
                    }
                    image {
                        id
                        image_url
                       
                      }
                  }
                } 
          }
        }
        `
      })
  }).then(res => res.json())
})


export { fetchAllUserFlowNamesByAppId, 
        fetchUserFlowByAppId, 
        fetchiOSUserFlowByAppId, 
        fetchUserFlowById, 
        fetchIOSUserFlowById, 
        createUserflowWithoutScreens, 
        updateUserflowByID,
        addScreensObjsToUserflow,
        updateUserFlowScreens
      }


